
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ChartDataSets, ChartOptions } from 'chart.js';
import RatesGraphTooltip from '@/modules/rates/components/graph/rates-graph-tooltip.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import GraphHotelsLegend from '@/modules/common/components/graph-hotels-legend.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import type Day from '@/modules/common/types/day.type';
import setScrollbarPadding from '@/modules/common/filters/scrollbar-padding.filter';
import DayRateTooltipHotel from './day-rate-tooltip-hotel.vue';
import RatesFiltersService, { RatesFiltersServiceS } from '../../rates-filters.service';

export interface IDisabled {
    val: boolean;
    id: number;
}

@Component({
    components: {
        CustomGraph,
        RatesGraphTooltip,
        GraphHotelsLegend,
        DayRateTooltipHotel,
    },
})
export default class RatesGraphHotels extends Vue {
    @Inject(RatesServiceS)
    private ratesService!: RatesService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(RatesFiltersServiceS)
    private ratesFiltersService!: RatesFiltersService;

    @Inject(HotelsServiceS)
    protected hotelsService!: HotelsService;

    @Inject(UserServiceS)
    protected userService!: UserService;

    private disabledChart: (number | string)[] = [];

    tooltipDay: Day | null = null;
    tooltipFocusElement: HTMLElement | null = null;

    get mainHotelId(): number | null {
        return +this.$route.params.hotelId || this.userService.currentHotelId;
    }

    get isAllChannelsMode() {
        return this.ratesService.isAllChannelsMode;
    }

    get hotelList() {
        if (this.isAllChannelsMode) {
            return this.ratesService.availableProviders;
        }

        const { competitors: choosenCompetitors } = this.documentFiltersService;
        const { competitors: globalCompetitors } = this.compsetsService;

        if (!choosenCompetitors || !globalCompetitors) return [];

        return [this.mainHotelId!, ...choosenCompetitors]
            .filter(hotelId => hotelId === this.mainHotelId || globalCompetitors.includes(hotelId))
            .sort((a, b) => a - b);
    }

    get chartData(): { labels: (Day | string)[][], datasets: ChartDataSets[] } | null {
        const datasets: ChartDataSets[] = [{}];
        const { days } = this.documentFiltersService;

        let maxPrice = 0;

        if (!days || !this.mainHotelId) {
            return null;
        }

        this.hotelList.forEach((hotelId: string | number) => {
            const isDisabled = this.disabledChart.includes(hotelId);
            const isMain = hotelId === this.mainHotelId;
            const data = days.map(day => {
                const room = this.ratesService.getRoom(day, hotelId as number);

                return room
                    // NOTE: Null needs to show Sold Out on the Graph (CI-2255)
                    ? this.ratesService.switchPrice(room) || null
                    : null;
            });

            datasets.push({
                label: String(hotelId),
                data,
                borderColor: this.borderColor(hotelId),
                borderWidth: isMain ? 3 : 2,
                lineTension: 0,
                borderJoinStyle: 'round',
                hidden: isDisabled,
            });

            const prices = data.filter(price => price !== null) as number[];
            const maxHotelPrice = Math.max(...(prices.length ? prices : [0]));

            if (maxPrice < maxHotelPrice) {
                maxPrice = maxHotelPrice;
            }
        });

        this.$emit('calculate-prices', { maxPrice });

        const { year, month } = this.documentFiltersService;
        const labels = days.map(day => [day, this.$t(`dayShort.${new Date(year, month, day).getDay()}`).toString()]);

        return {
            labels,
            datasets,
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    tension: 0,
                },
                point: {
                    radius: 3,
                    backgroundColor: '#ECF1F5',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                    },
                    offset: true,
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    parseLabel(label: string | null) {
        return label ? String(parseInt(label, 10)) : null;
    }

    setCurrentDay(day: string | null) {
        this.$emit('current-day', this.parseLabel(day));
        this.tooltipDay = this.parseLabel(day) as unknown as Day;
        this.tooltipDay = this.tooltipDay && +this.tooltipDay as Day;
    }

    updated() {
        setScrollbarPadding();
    }

    onTooltipClick(dayLabel: string) {
        const hotelId = String(this.userService.currentHotelId);
        const day = String(this.parseLabel(dayLabel));

        if (hotelId) {
            this.$router.push({
                name: `${this.$route.name}.day-rate`,
                params: { hotelId, day },
            });
        }
    }

    borderColor(hotelId: number | string) {
        const { currentHotelId } = this.userService;

        if (typeof hotelId === 'string') {
            const index = (this.hotelList as string[]).indexOf(hotelId);
            return this.userService.chartColors[index];
        }

        return currentHotelId && hotelId === currentHotelId
            ? CURRENT_HOTEL_GRAPH_COLOR
            : this.colorByHotel(hotelId);
    }

    colorByHotel(hotelId: number | string) {
        if (typeof hotelId === 'string') {
            const index = (this.hotelList as string[]).indexOf(hotelId);
            return this.userService.chartColors[index];
        }

        return this.hotelsService.getHotelsGraphColor()[hotelId];
    }

    resetGraph() {
        this.disabledChart = [];
    }

    setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }
}
